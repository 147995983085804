import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AccessToken, AuthState, IDToken, OktaAuth, Tokens, UserClaims } from '@okta/okta-auth-js';
import { assertNever } from 'assert-never';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, take, tap } from 'rxjs/operators';

import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { InactivityService } from '@shure/cloud/shared/utils/inactivity';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { DecodedToken, TokenError, TokenResponse, UserProfile } from '../lib/models/okta-interface.model';

/**
 *
 * @param oktaInterface
 * @param callBacks
 *        Optional methods to invoke when/if the user logs In or logs Out.
 * @returns
 */
export function monitorLoginState(
	oktaInterface: OktaInterfaceService,
	callBacks: {
		onLogIn?: () => void;
		onLogOut?: () => void;
	}
): void {
	oktaInterface.$isUserAuthenticated.subscribe((userLoggedIn) => {
		if (userLoggedIn) {
			if (callBacks.onLogIn) callBacks.onLogIn();
		} else {
			if (callBacks.onLogOut) callBacks.onLogOut();
		}
	});
}

@Injectable({
	providedIn: 'root'
})
export class OktaInterfaceService {
	public $isUserAuthenticated!: Observable<boolean>;
	public $idTokenClaims = new BehaviorSubject<UserClaims | null>(null);
	public $accessTokenClaims = new BehaviorSubject<UserClaims | null>(null);

	constructor(
		public authStateService: OktaAuthStateService,
		public inactivityService: InactivityService,
		private translocoService: TranslocoService, // TODO: LBF 3/14/2022 - remove CMR-54
		public http: HttpClient,
		@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
		private readonly logger: ILogger,
		@Inject(APP_ENVIRONMENT) public appEnv: AppEnvironment,
		private readonly router: Router
	) {
		this.logger = logger.createScopedLogger('OktaInterfaceService');

		this.$isUserAuthenticated = this.authStateService.authState$.pipe(
			filter((s: AuthState) => !!s && s.isAuthenticated !== undefined),
			map((s: AuthState) => s.isAuthenticated ?? false),
			distinctUntilChanged(), // stop duplicate emissions of auth state which occur during token refresh
			tap((isAuthenticated: boolean) => this.logger.debug('authState$: ', `${isAuthenticated}`)),
			shareReplay({ bufferSize: 1, refCount: true })
		);

		this.$isUserAuthenticated.subscribe((isAuthenticated) => {
			if (isAuthenticated) {
				this.handleToAuthorizedTransition();
			} else {
				this.handleToUnauthorizedTransition();
			}
		});

		this.inactivityService.idleTimeout$.subscribe(() => this.signOut());
		this.inactivityService.signOutNow$.subscribe(() => this.signOut());
	}

	/**
	 * signOut a user.
	 * This method will signOut a user from the application. Based on an env setting, it will
	 * either signout the user from Okta or just from the app (local)
	 * Details here: https://developer.okta.com/docs/guides/sign-users-out/react/main/
	 */
	public async signOut(): Promise<void> {
		const signOutScope = this.appEnv.signOutScope ?? 'signout-okta';
		this.logger.debug('signOut', 'signOutScope', signOutScope);

		switch (signOutScope) {
			case 'signout-okta':
				this.signOutOkta();
				break;

			case 'signout-app':
				this.signOutOktaApp();
				break;

			default:
				assertNever(signOutScope);
		}
	}
	/**
	 * This method Signs Out the user completely from the Okta
	 * Reference: https://help.okta.com/en-us/content/topics/apps/apps_single_logout.htm#:~:text=Enable%20SLO%20for%20OIDC%20integrations
	
	 * Since the Okta inbuilt methods like `await this.oktaAuth.revokeAccessToken()` and `this.oktaAuth.tokenManager.clear()` 
	 * are not functioning properly in Firefox, we are manually clearing the storage values when the user logs out of the application.
	 */
	public async signOutOkta(): Promise<void> {
		// Cleared tokens manually to ensure other tabs are aware of the session logout.
		try {
			sessionStorage.removeItem('okta-token-storage');
			localStorage.removeItem('okta-token-storage');
			await this.oktaAuth.session.close();
			window.open(
				this.oktaAuth.options.postLogoutRedirectUri ? this.oktaAuth.options.postLogoutRedirectUri : '/',
				'_self'
			);
		} catch (error) {
			if (error instanceof Error) {
				this.logger.error('signOutOkta', `Error encountered while signing out: ${error.message}`);
			}
		}
	}

	/**
	 * This method Signs Out the user from the respective app
	 */
	public signOutOktaApp(): void {
		this.oktaAuth.tokenManager.clear();
		window.open(this.oktaAuth.options.postLogoutRedirectUri, '_self');
	}
	/**
	 * @returns Okta's accessToken string
	 */
	public getAccessTokenJWT(): string {
		return <string>this.oktaAuth.getAccessToken();
	}

	/**
	 * @returns Okta's refreshToken string
	 */
	public getRefreshTokenJWT(): string {
		return <string>this.oktaAuth.getRefreshToken();
	}

	/**
	 * Make a POST call to OKTA to exercise the inline hook with the supplied tenant ID
	 * The Okta Inline hook will return a JWT with the tenant ID in the shure_tenant to use for the session
	 * @param tenantId
	 * @param persist - If `persist` is true, the organization ID will be saved as the default tenant,
	 * which will be applied automatically on the next login.
	 * @returns
	 */
	public setOktaSessionTenant$(tenantId: string, persist = false): Observable<void> {
		/* eslint-disable @typescript-eslint/naming-convention */
		const oktaScopes = <string[]>this.oktaAuth.options.scopes;
		const body = new URLSearchParams();
		body.set('client_id', encodeURIComponent(<string>this.oktaAuth.options.clientId));
		body.set('grant_type', encodeURIComponent('refresh_token'));
		body.set('scope', oktaScopes.toString().replace(/,/gi, ' '));
		body.set('refresh_token', encodeURIComponent(this.getRefreshTokenJWT()));

		const headers = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded'
		});

		return this.http
			.post<TokenResponse>(`${this.oktaAuth.options.issuer}/v1/token`, body.toString(), {
				headers: headers,
				params: { shure_tenant: tenantId, persist }
			})
			.pipe(
				take(1),
				map((response: TokenResponse) => {
					this.updateTokenStorage(response);
				}),
				catchError((error) => {
					// Handle the error here, re-throw the error
					return throwError(() => error);
				})
			);
		/* eslint-enable */
	}

	public getUserEmail$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.email;
			})
		);
	}

	public getUsername$(): Observable<string> {
		return this.$idTokenClaims.pipe(
			map((tokenClaims) => {
				return <string>tokenClaims?.name;
			})
		);
	}

	public getUserFirstName$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.firstName;
			})
		);
	}

	public getUserLastName$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.lastName;
			})
		);
	}

	public getUserRole$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.role;
			})
		);
	}

	public getUserId$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.userId;
			})
		);
	}

	public getDefaultOrgId$(): Observable<string> {
		return this.getUserProfile$().pipe(
			map((userProfile) => {
				return userProfile.orgId;
			})
		);
	}

	public getUserProfile$(): Observable<UserProfile> {
		return this.$idTokenClaims.pipe(
			filter((tokenClaims) => !!tokenClaims),
			map((tokenClaims) => {
				const name = <string>tokenClaims?.name;
				const storageToken: Tokens = this.oktaAuth.storageManager.getTokenStorage().getStorage();
				const idToken = storageToken.idToken?.idToken;
				const idTokenData = { userType: '', uId: '', orgId: '', orgName: '' };
				if (idToken) {
					const decodedIdToken = <DecodedToken>jwtDecode(idToken);

					if (decodedIdToken) {
						idTokenData.userType = decodedIdToken.shure_role;
						idTokenData.uId = decodedIdToken.shure_userId;
						idTokenData.orgId = decodedIdToken.shure_tenant;
						idTokenData.orgName = decodedIdToken.shure_tenant_name;
					}
				}

				return {
					firstName: name ? name.split(' ')[0] : '',
					lastName: name ? name.split(' ')[1] : '',
					email: tokenClaims?.email ? tokenClaims?.email : '',
					// TODO: LBF 3/14/2022 - get locale from Okta when available
					// locale: tokenClaims?.locale ? tokenClaims?.locale : 'en',
					locale: this.translocoService.getActiveLang(),
					role: idTokenData.userType,
					userId: idTokenData.uId,
					orgId: idTokenData.orgId,
					orgName: idTokenData.orgName
				};
			})
		);
	}

	public handleToUnauthorizedTransition(): void {
		this.logger.debug('handleToUnauthorizedTranstion', 'stopping inactivity monitoring');
		this.inactivityService.stopMonitoring();
		this.oktaAuth.tokenManager.off('renewed');
	}

	/**
	 * Update the tokens in storage
	 * This will trigger the this.authStateService.authState$ subscription
	 * @param responseTokens
	 */
	public updateTokenStorage(responseTokens: TokenResponse): void {
		const storageToken: Tokens = this.oktaAuth.storageManager.getTokenStorage().getStorage();
		if (storageToken.accessToken) {
			storageToken.accessToken.accessToken = responseTokens.access_token;
			storageToken.accessToken.claims = jwtDecode(responseTokens.access_token);
		}

		if (storageToken.idToken) {
			storageToken.idToken.idToken = responseTokens.id_token;
			storageToken.idToken.claims = jwtDecode(responseTokens.id_token);
		}

		if (storageToken.refreshToken) {
			storageToken.refreshToken.refreshToken = responseTokens.refresh_token;
			// don't muck with any other fields in the refresh token
		}
		return this.oktaAuth.tokenManager.setTokens(storageToken);
	}

	public handleToAuthorizedTransition(): void {
		this.logger.debug('handleToUnauthorizedTranstion', 'starting inactivity monitoring, emitting user claim info');
		this.inactivityService.startMonitoring();
		this.oktaAuth.tokenManager.on('renewed', (key, token) => {
			// only one token is emitted at a time
			if (key === 'idToken') {
				this.validateIdToken(<IDToken>token);
			}
			if (key === 'accessToken') {
				this.validateAccessToken(<AccessToken>token);
			}
			this.emitCurrentUserClaimInfo();
		});
		this.emitCurrentUserClaimInfo();
	}

	private hasValidRoles(role: string): boolean {
		if (this.appEnv.appRoles.length < 1) {
			return true;
		}

		return this.appEnv.appRoles.some((appRole) => role === appRole);
	}

	private validateAccessToken(token: AccessToken): void {
		// eslint-disable-next-line dot-notation
		const tenant = token.claims['shure_tenant'];
		const missingTenant = tenant === undefined;
		this.logger.debug('validateToken', 'shure_tenant from token', tenant);

		if (!missingTenant || this.appEnv.appType === 'admin') {
			return;
		}
		if (this.appEnv.appType !== 'cloud') {
			// we are in a portal and should logout and return to shure cloud to handle the error
			this.signOut();
		}
	}

	private validateIdToken(token: IDToken): void {
		// eslint-disable-next-line dot-notation
		const role = token.claims['shure_role']?.toString();
		const missingRole = !this.hasValidRoles(role);
		this.logger.debug('validateToken', 'shure_tole from token', role);

		if (!missingRole) {
			return;
		}
		if (this.appEnv.appType !== 'cloud') {
			// we are in a portal and should logout and return to shure cloud to handle the error
			this.signOut();
		}
	}

	// NOTE: this class exists in ignite, and all other portals. The check/logout needs to be generic enough
	// to work for all of them.
	private async emitCurrentUserClaimInfo(): Promise<void> {
		const tokens: Tokens = this.oktaAuth.storageManager.getTokenStorage().getStorage();
		//Temporary fix until the other apps(Motive) updates to use session storage
		localStorage.setItem('okta-token-storage', JSON.stringify(tokens));
		if (tokens.accessToken && tokens.idToken) {
			this.validateAccessToken(tokens.accessToken);
			this.validateIdToken(tokens.idToken);
			this.$accessTokenClaims.next(tokens.accessToken.claims);
			this.$idTokenClaims.next(tokens.idToken.claims);
		} else {
			// We need both tokens for this app to work
			// id token to know their permissions
			// access token to pass to the API
			this.router.navigate([''], {
				state: {
					error: TokenError.Unknown
				}
			});
		}
	}
}
