import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@jsverse/transloco';

/**
 * ```sh-id-button``` is a wrapper for a mat-icon-button adding a pulsating effect
 * when the button is active to visualise the identifying state for a device.
 */
@Component({
	selector: 'sh-id-button',
	templateUrl: './id-button.component.html',
	styleUrls: ['./id-button.component.scss'],
	standalone: true,
	imports: [CommonModule, MatButtonModule, MatTooltipModule, TranslocoModule]
})
export class IdButtonComponent {
	/**
	 * Id incrementer allowing for multiple <sh-id-button> in same view.
	 * @ignore internal
	 */
	public static nextId = 0;

	public readonly id = input<string>(`sh-id-button-${IdButtonComponent.nextId++}`);

	/**
	 * Determines if the ID button is identifying.
	 */
	public readonly isIdentifying = input<boolean>(false);

	/**
	 * Disable ID button.
	 */
	public readonly disabled = input<boolean>(false);

	/**
	 * Emits the value of isIdentifying on id button click.
	 */
	public toggleIdentify = output<boolean>();

	public identifyDevice(event: Event): void {
		this.toggleIdentify.emit(!this.isIdentifying());
		event.stopPropagation();
	}
}
