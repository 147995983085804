<footer *transloco="let t">
	<div id="sh-footer-container-div">
		<div id="sh-footer-link-list-div">
			<div id="sh-footer-buttons-container" *ngIf="!isSmallDevice()">
				<button
					class="sh-button-link"
					id="sh-footer-{{ footerLink.id }}-button"
					mat-button
					*ngFor="let footerLink of activeFooterLinksList"
					(click)="onFooterLinkClick(footerLink)"
				>
					<span id="sh-footer-{{ footerLink.id }}-label">{{ footerLink.label() }}</span>
				</button>
			</div>

			<div id="sh-footer-copyright-div">{{ t('cloud.shared.footer.shure-rights', { toDate: currentYear }) }}</div>

			<div id="sh-footer-menu-container" *ngIf="isSmallDevice()">
				<button
					mat-icon-button
					role="menuBtn"
					aria-haspopup="menu"
					aria-controls="menu"
					id="sh-footer-links-selector"
					[matMenuTriggerFor]="menu"
				>
					<mat-icon fontSet="prism-mat-sharp" fontIcon="more_vert"></mat-icon>
				</button>

				<mat-menu role="menu" #menu="matMenu" xPosition="before">
					<button
						id="sh-footer-{{ footerLink.id }}-item"
						mat-menu-item
						role="menuitem"
						*ngFor="let footerLink of activeFooterLinksList"
						(click)="onFooterLinkClick(footerLink)"
					>
						{{ footerLink.label() }}
					</button>
				</mat-menu>
			</div>
		</div>
	</div>
</footer>
