/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { DeviceDetectorService } from 'ngx-device-detector';

import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';

import { AnalyticsAppInfo, AnalyticsSuperProperties } from '../models';

@Injectable()
export class AnalyticsService {
	private appInfo!: AnalyticsAppInfo;
	private isAnalyticsOpted = false;
	public mixPanel: Mixpanel;
	public oktaTokenClaims = toSignal(this.oktaInterfaceService.$idTokenClaims);

	constructor(
		private deviceService: DeviceDetectorService,
		private oktaInterfaceService: OktaInterfaceService
	) {
		this.mixPanel = mixpanel;
	}

	/**
	 * Set app name and version information for analytics
	 */
	public setApplicationInfo(appInfo: AnalyticsAppInfo): void {
		this.appInfo = appInfo;
	}

	/**
	 * Set the analytic data from application
	 * @param event - String representation of event id
	 * @param data - DataObjectType representation of data from event
	 */
	public analyticDataCollector(event: string, data: object): void {
		this.submitToAnalyticsPlatform(event, data);
	}

	/**
	 * Sets the analytics opt in status
	 */
	public setOptIn(): void {
		this.isAnalyticsOpted = true;
	}

	/**
	 *  Sets the analytics opt out status
	 */
	public setOptOut(): void {
		this.isAnalyticsOpted = false;
	}

	/**
	 * Submit the Analytic data
	 * @param event - String representation of event id
	 * @param data - DataObjectType representation of data from event
	 */
	public submitToAnalyticsPlatform(event: string, data: object): void {
		if (this.isAnalyticsOpted) {
			const eventData = {
				...data,
				...this.getSuperProperties()
			};
			this.mixPanel.track(event, eventData);
		}
	}

	/**
	 * Gets the super or common properties of an analytics event
	 */
	private getSuperProperties(): AnalyticsSuperProperties {
		return {
			...this.appInfo,
			distinct_id: this.oktaTokenClaims()?.sub ?? '',
			session_id: this.oktaTokenClaims()?.jti ?? '',
			browser_name:
				this.deviceService.browser === 'MS-Edge-Chromium' ? 'Microsoft Edge' : this.deviceService.browser,
			browser_version: this.deviceService.browser_version,
			os_version: this.getOsVersion(this.deviceService.os),
			os_product: this.getOsProduct(this.deviceService.os),
			os_manufacturer: this.getManufacturerName(),
			timestamp: Date.now(),
			// eslint-disable-next-line dot-notation
			country: <string>this.oktaTokenClaims()?.['Custom_Country'] ?? ''
		};
	}

	/**
	 * Gets the os manufacturer name
	 */
	private getManufacturerName(): string {
		const os = this.deviceService.os;
		switch (os) {
			case 'Windows':
				return 'Microsoft';
			case 'Mac':
				return 'Apple';
			case 'Android':
				return 'Android (Google)';
			case 'iOS':
				return 'iOS (Apple)';
			default:
				return `Other operating system ${os}`;
		}
	}

	/**
	 * Gets the os product
	 */
	private getOsProduct(os: string): string {
		//for ipad we should send iPadOS
		if (os === 'iOS' && this.deviceService.deviceType === 'tablet') {
			return 'iPadOS';
		}
		return os;
	}

	/**
	 * Gets the os version
	 */
	private getOsVersion(os: string): string {
		//As we are unable to retrieve the right versions for OS other than windows, we return NA
		if (os === 'Windows') {
			const osVersion = this.deviceService.os_version;
			const formatOsVersion = osVersion?.split('-').join(' ');
			return formatOsVersion?.charAt(0)?.toUpperCase() + formatOsVersion.slice(1);
		}
		return 'NA';
	}
}
