import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

import { DeviceStatus } from '@shure/cloud/shared/models/devices';

/**
 * ```sh-device-state-bar``` is a view component for displaying color codes based on the DeviceStatus of a device.
 */
@Component({
	selector: 'sh-device-state-bar',
	templateUrl: './device-state-bar.component.html',
	styleUrls: ['./device-state-bar.component.scss'],
	standalone: true,
	imports: [CommonModule, MatTooltipModule, TranslocoModule]
})
export class DeviceStateBarComponent {
	public deviceStateTooltip = '';
	public state!: DeviceStatus;

	@Input() public set deviceState(deviceState: DeviceStatus) {
		this.state = deviceState;
		this.deviceStateTooltip = this.getTooltipMessage();
	}

	constructor(private readonly translocoService: TranslocoService) {}

	public get deviceStateEnum(): typeof DeviceStatus {
		return DeviceStatus;
	}

	private getTooltipMessage(): string {
		switch (this.state) {
			case DeviceStatus.Online:
				return this.translocoService.translate('cloud.shared.device-state-bar.online');
			case DeviceStatus.Offline:
				return this.translocoService.translate('cloud.shared.device-state-bar.offline');
			case DeviceStatus.Discovered:
				return this.translocoService.translate('cloud.shared.device-state-bar.device-discovered');
			case DeviceStatus.Error:
				return this.translocoService.translate('cloud.shared.device-state-bar.device-discovery-error');
			// Default state when the device's actual status doesn't match known states.
			case DeviceStatus.Unknown:
				return this.translocoService.translate('cloud.shared.device-state-bar.unknown');
			default:
				return '';
		}
	}
}
